import axios from "axios";

const structureService = {
  getCurrentStructure: async (): Promise<any> => {
    const { data } = await axios.get<any>(`/structures/current`);

    return data;
  },
};

export default structureService;
