import { createApp } from "vue";
import "./style.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios, { AxiosError } from "axios";
import * as dayjs from "dayjs";
import * as Sentry from "@sentry/vue";

import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";

import Toast, { PluginOptions } from "vue-toastification";
import "vue-toastification/dist/index.css";
const options: PluginOptions = {};

import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);

import "dayjs/locale/fr";
import { APIPlatformError } from "./types/utils";

dayjs.locale("fr");

// we do not configure the http or https to let the browser use the same protocol as the loaded page.
axios.defaults.baseURL = `//api.${window.env.DOMAIN}/api`;
axios.defaults.withCredentials = true;

getCurrentBrowserFingerPrint().then((fingerprint) => {
  axios.defaults.headers["x-device-fingerprint"] = fingerprint;
});

axios.interceptors.response.use(
  (res) => res,
  (error: AxiosError<APIPlatformError>) => {
    if (error.response) {
      if (error.response.data["hydra:member"]) {
        error.message = error.response.data["hydra:member"];
      } else if (error.response.data["hydra:description"]) {
        error.message = error.response.data["hydra:description"];
      } else if (error.response.data.message) {
        error.message = error.response.data.message;
      } else if (error.response.statusText) {
        error.message = error.response.statusText;
      }
    }

    const currentRoute = router.currentRoute.value;
    if (error.response!.status == 401 && currentRoute?.name !== "login") {
      if (currentRoute) {
        store.commit("setCurrentUser", null);
      }
      return router.push({ name: "login" });
    }

    return Promise.reject(error);
  }
);

const app = createApp(App);

if (window.env.SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: window.env.SENTRY_DSN,
    environment: window.env.SENTRY_ENVIRONMENT,
    release: window.env.SENTRY_RELEASE,
  });
}

app.use(store).use(router).use(Toast, options).mount("#app");
