import { Enrolment } from "./enrolment";
import { APIPlatformObject, Image } from "./utils";

export enum ROLE {
  USER = "ROLE_USER",
  STUDENT = "ROLE_STUDENT",
  PROFESSOR = "ROLE_PROFESSOR",
  MODERATOR = "ROLE_MODERATOR",
  YOUSCHOOL = "ROLE_YOUSCHOOL",
  ADMIN = "ROLE_ADMIN",
  SUPER_ADMIN = "ROLE_SUPER_ADMIN",
}

export interface User extends APIPlatformObject {
  id: string;
  username: string;
  email: string;
  roles: ROLE[];
  enrolments: Enrolment[];
  firstName: string;
  lastName: string;
  fullName: string;
  avatar: Image;
  disabled: boolean;
}
