import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from "vue-router";
import NotFound from "./views/NotFound.vue";
import AccessDenied from "./views/AccessDenied.vue";
import { attemptService, userService } from "./services/api";
import Cookies from "js-cookie";
import store from "./store";
import accessDeniedRoute from "./helpers/accessDeniedRedirect";
import AppLayout from "./views/AppLayout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: {
      template: `<router-view />`,
    },
    beforeEnter: authenticationGuard,
    children: [
      {
        path: "",
        component: AppLayout,
        children: [
          {
            path: "",
            name: "home",
            redirect: { name: "calendar" },
          },
          {
            path: "suggestions",
            name: "suggestions",
            component: () => import("./views/app/SuggestionsView.vue"),
          },
          {
            path: "calendar",
            name: "calendar",
            component: () => import("./views/app/CalendarView.vue"),
          },
          {
            path: "attempts",
            name: "attempts",
            component: () => import("./views/app/ExamsAttemptsView.vue"),
          },
          {
            name: "test-attempt",
            path: "attempts/tests/:attemptId",
            component: () => import("./views/main/TestAttempt.vue"),
            beforeEnter: async (to, from, next) => {
              const attemptId = to.params.attemptId as string;
              try {
                to.meta.attempt = await attemptService.getAttempt(attemptId);
                next();
              } catch (error) {
                next(from);
              }
            },
          },
          {
            name: "homework-attempt",
            path: "attempts/homeworks/:attemptId",
            component: () => import("./views/main/HomeworkAttempt.vue"),
            beforeEnter: async (to, from, next) => {
              const attemptId = to.params.attemptId as string;
              try {
                to.meta.attempt = await attemptService.getAttempt(attemptId);
                next();
              } catch (error) {
                next(from);
              }
            },
          },
        ],
      },
    ],
  },
  { path: "/:pathMatch(.*)*", name: "not-found", component: NotFound },
  { path: "/:pathMatch(.*)*", name: "access-denied", component: AccessDenied },
];

async function authenticationGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  const currentUser = store.getters.currentUser;

  if (!currentUser) {
    const domain = window.env.DOMAIN;
    window.location = `//${domain}/login?redirectUrl=${window.location.href}` as unknown as Location;
    return;
  }

  if (currentUser.roles.find((role) => role === "ROLE_STUDENT")) {
    return next(accessDeniedRoute(to));
  }

  next();
}
async function userResolver() {
  const cookiePrefix = btoa(document.location.host.replace("middle.", "")).slice(0, 6);
  const hasJwtHeaderPayloadCookie = Cookies.get(cookiePrefix + "_jwt_hp");
  const hasCurrentUser = store.getters.hasCurrentUser;

  if (hasJwtHeaderPayloadCookie && !hasCurrentUser) {
    try {
      const user = await userService.authenticate();
      store.commit("setCurrentUser", user);
    } catch (error) {
      // Redirect sur le front
    }
  }
}

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(userResolver);

export default router;
