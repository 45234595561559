<template>
  <router-view />
</template>
<script setup lang="ts">
import { structureService } from "./services/api";
import { onMounted } from "vue";
import { useStore } from "vuex";
import { MutationTypes } from "./types/store";

const store = useStore();

onMounted(async () => {
  const structure = await structureService.getCurrentStructure();
  store.commit(MutationTypes.SET_CURRENT_STRUCTURE, structure);
  document.title = "Middle Office | " + structure.name;
});
</script>
