import { createStore, MutationTree } from "vuex";
import { State, Mutations, MutationTypes, Store } from "../types/store";
import { User } from "../types/user";
import { Training } from "../types/training";

const getters = {
  currentUser: (state: State): User | null => state.currentUser,
  currentStructure: (state: State) => state.currentStructure,
  hasCurrentUser: (state: State): boolean => state.currentUser !== null,
  enrolledTrainings: (state: State): Training[] => state.enrolledTrainings,
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_CURRENT_USER](state, payload: User) {
    state.currentUser = payload;
  },
  [MutationTypes.SET_CURRENT_STRUCTURE](state, payload) {
    state.currentStructure = payload;
  },
  [MutationTypes.SET_ENROLLED_TRAININGS](state, payload) {
    state.enrolledTrainings = payload;
  },
};

const store = createStore<State>({
  state: {
    currentUser: null,
    currentStructure: null,
    enrolledTrainings: [],
  },
  mutations,
  getters,
});

export default store as Store;
