import { CommitOptions, Store as VuexStore } from "vuex";
import { User } from "./user";
import { Training } from "./training";

export type State = {
  currentUser: User | null;
  enrolledTrainings: Training[];
};

export type Getters = {
  currentUser(state: State): User | null;
  hasCurrentUser(state: State): boolean;
  enrolledTrainings(state: State): Training[];
};

export enum MutationTypes {
  SET_CURRENT_USER = "setCurrentUser",
  SET_CURRENT_STRUCTURE = "setCurrentStructure",
  SET_ENROLLED_TRAININGS = "setEnrolledTrainings",
}

export type Mutations<S = State> = {
  [MutationTypes.SET_CURRENT_USER](state: S, payload: User): void;
  [MutationTypes.SET_CURRENT_TRAINING](state: S, payload: Training): void;
  [MutationTypes.SET_CURRENT_STRUCTURE](state: S, payload): void;
  [MutationTypes.SET_ENROLLED_TRAININGS](state: S, payload: Training[]): void;
};

export type Store = Omit<VuexStore<State>, "getters"> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(key: K, payload: P, options?: CommitOptions): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
};
