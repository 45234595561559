import { RouteLocationNormalized } from "vue-router";

const accessDeniedRoute = (to: RouteLocationNormalized) => ({
  ...to,
  name: "access-denied",
  params: {
    pathMatch: to.path
      .slice(1, to.path.length) // remove first '/'
      .split("/"),
  },
});

export default accessDeniedRoute;
